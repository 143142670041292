import React, { useContext } from 'react'
import { Menu, Space, Button, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import "./dropDownMenu.css"
import { QueryContext } from '../../../context/QueryContext';

function DropDownMenu() {
  const { queryState, queryDispatch } = useContext(QueryContext);
  // const { SubMenu } = Menu;


  const onClick=(e)=>{
    // console.log(e.domEvent.target.outerText)
    queryDispatch({ type: "UPDATE_FILTER_PARAM", value: e.key, filtervalue: e.domEvent.target.outerText})
  }

  const menu = (
    <Menu onClick={e=>onClick(e)}>
      <Menu.Item key="All">
        All
      </Menu.Item>
      <Menu.Item key="BYPASSED">
        <span>Bypassed</span>
      </Menu.Item>
      <Menu.Item key="LESS%20THAN%20THRESHOLD">
        <span>Less Than Threshold</span>
      </Menu.Item>
      <Menu.Item key="not%20ekyc">
        <span>Not Sent for Ekyc</span>
      </Menu.Item>
      <Menu.Item key="ekyc">
        <span>Sent for Ekyc</span>
      </Menu.Item>
      <Menu.Item key="Pass">
        <span>Ekyc Passed</span>
      </Menu.Item>
      <Menu.Item key="Reject">
        <span>Ekyc Failed</span>
      </Menu.Item>
      {/* <Menu.Item key="authenticated">
        <span>Authenticated</span>
      </Menu.Item> */}
      {/* <SubMenu title="ekyc">
        <Menu.Item key="1.0">Passed</Menu.Item>
        <Menu.Item key="1.1">Failed</Menu.Item>
      </SubMenu> */}
    </Menu>
  );

  return (
    <React.Fragment>
      <Dropdown overlay={menu} trigger={["click"]}>
        <Button style={{ width: "11em", textAlign: "left", padding: "2px 4px 4px 6px"}}>
            <span style={{width: "9em"}}>
              {queryState.filterText}
            </span>
            <span className="ant-dropdown-link" onChange={e => e.preventDefault()}>
              <DownOutlined style={{verticalAlign: "middle"}}/>
            </span>
        </Button>
      </Dropdown>
    </React.Fragment>
  )
}

export default DropDownMenu
